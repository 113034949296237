<template>
  <div>
    <validation-observer ref="form" v-slot="{ handleSubmit }">
      <v-form @submit.stop.prevent="handleSubmit(onSubmit)">
        <v-card outlined>
          <v-card-title> Creating new legal-typology</v-card-title>
          <v-card-text>
            <!-- Code Field -->
            <v-text-field v-model="legal_typology.code" :label="$t('code')" />

            <!-- Name Fields for English and French -->
            <v-text-field v-model="legal_typology.name.en" :label="$t('name_en')" />
            <v-text-field v-model="legal_typology.name.fr" :label="$t('name_fr')" />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn type="submit" color="primary">
              {{ $t("actions.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import formatter from "@/mixins/formatter";

@Component({
  mixins: [formatter],
})
export default class LegalTypologyForm extends Vue {
  // Initialize legal_typology with the correct structure
  legal_typology = {
    name: {
      fr: "", // French name
      en: "", // English name
    },
    code: "", // Code field
  };

  // Keep the mounted hook but don't reset the data structure
  mounted() {
    // You may fetch data or manipulate this object here if needed
  }

  // Submit handler
  onSubmit() {
    const call = this.$api.post(`legal-typology`, this.legal_typology);

    call
      .then((j) => {
        this.$emit("save", j.data);
      })
      .catch((err) => {
        if (err.response) this.$refs.form.setErrors(err.response.data);
        this.$snack.validationError(err.response.data);
      });
  }
}
</script>

<style scoped>
/* Add any specific styles here */
</style>
