var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("validation-observer", {
        ref: "form",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "v-form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return handleSubmit(_vm.onSubmit)
                      },
                    },
                  },
                  [
                    _c(
                      "v-card",
                      { attrs: { outlined: "" } },
                      [
                        _c("v-card-title", [
                          _vm._v(" Creating new legal-typology"),
                        ]),
                        _c(
                          "v-card-text",
                          [
                            _c("v-text-field", {
                              attrs: { label: _vm.$t("code") },
                              model: {
                                value: _vm.legal_typology.code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.legal_typology, "code", $$v)
                                },
                                expression: "legal_typology.code",
                              },
                            }),
                            _c("v-text-field", {
                              attrs: { label: _vm.$t("name_en") },
                              model: {
                                value: _vm.legal_typology.name.en,
                                callback: function ($$v) {
                                  _vm.$set(_vm.legal_typology.name, "en", $$v)
                                },
                                expression: "legal_typology.name.en",
                              },
                            }),
                            _c("v-text-field", {
                              attrs: { label: _vm.$t("name_fr") },
                              model: {
                                value: _vm.legal_typology.name.fr,
                                callback: function ($$v) {
                                  _vm.$set(_vm.legal_typology.name, "fr", $$v)
                                },
                                expression: "legal_typology.name.fr",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              { attrs: { type: "submit", color: "primary" } },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("actions.save")) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }